import React from 'react';
import image1 from '../assets/images/image1.jpg'; // Replace with actual image files
import image2 from '../assets/images/image2.jpeg';
import image3 from '../assets/images/image3.jpeg';

// Data for the articles
const articles = [
  {
    title: 'Building a React Portfolio',
    description: 'An in-depth look at how to build a personal portfolio using React and Tailwind CSS.',
    date: 'October 17, 2024',
    imageUrl: image1,  // Using "image 1"
    link: '/writing/react-portfolio', // The link to the detailed writing
  },
  // {
  //   title: 'Understanding JavaScript Closures',
  //   description: 'Learn about closures in JavaScript and how they work under the hood.',
  //   date: 'September 20, 2024',
  //   imageUrl: image2,  // Using "image 2"
  //   link: '/writing/js-closures', // The link to the detailed writing
  // },
  // {
  //   title: 'Deploying a Website with Vercel',
  //   description: 'A guide to deploying and hosting your React applications using Vercel.',
  //   date: 'August 18, 2024',
  //   imageUrl: image3,  // Using "image 3"
  //   link: '/writing/deploy-vercel', // The link to the detailed writing
  // },
];

function Home() {
  return (
    <div>
      {/* Header Section with auto-sizing based on the background image */}
      <div
        className="text-center bg-cover bg-center bg-gray-700 bg-blend-multiply relative"
        style={{
          backgroundImage: `url(${image2})`,
          paddingTop: '30%', // Controls the height dynamically based on the image size
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
          <h1 className="text-6xl font-bold text-white mb-6">Hello, I'm Furkan</h1>
          <p className="text-2xl text-gray-200">
            I'm a Software Engineer. On this website, you'll see a reflection of my life and hobbies, such as
            Photography, Design, Programming, and Traveling.
          </p>
        </div>
      </div>

      {/* Article Cards Section with the entire card as a clickable link */}
      <div className="container mx-auto p-8">
        {articles.map((article, index) => (
          <a
            key={index}
            href={article.link}
            className="block mb-8 rounded-lg hover:shadow-lg transition-shadow duration-300"
            style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }} // Subtle outer box (border more invisible)
          >
            <div className="flex justify-between items-center p-6">
              <div className="flex-1">
                <h2 className="text-3xl font-bold mb-4">{article.title}</h2> {/* Bigger text */}
                <p className="text-gray-600 mb-6 text-lg">{article.description}</p> {/* Bigger description */}
                <span className="text-gray-500 text-sm">{article.date}</span>
              </div>

              <div className="ml-8 w-1/3"> 
              <img src={article.imageUrl} alt={article.title} className="rounded-lg object-cover h-auto w-full" /> 
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Home;
