import React from 'react';

function Resume() {
  return (
    <section id="resume" className="resume py-10">
      <div className="container mx-auto">

        <div className="section-title mb-8">
          <h2 className="text-4xl font-bold text-center text-gray-800">Resume</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            <h3 className="resume-title text-2xl font-semibold text-gray-700 mb-4">Summary</h3>
            <div className="resume-item pb-0 mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Furkan Bayar</h4>
              <p className="text-gray-600"><em>Innovative, creative, and deadline-driven Computer Science Student with 3+ years of experience with Java and 5+ years of Linux development.</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Toronto, ON</li>
                <li>(416) 736-2100</li>
                <li><a href="mailto:bfurkan@my.yorku.ca" className="text-blue-600">bfurkan@my.yorku.ca</a></li>
              </ul>
            </div>

            <h3 className="resume-title text-2xl font-semibold text-gray-700 mb-4">Education</h3>
            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Bachelor of Arts - Computer Science</h4>
              <h5 className="text-gray-600">2021 - 2025</h5>
              <p className="text-gray-600"><em>York University, Toronto, ON</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Founder and President of TUSAYU (Turkish Student Association at York University)</li>
                <li>Experience Venture Award Recipient</li>
                <li>Lassonde Roborama Hackathon Winner - <a href="https://github.com/BFurkan/LassondeRoborama" className="text-blue-600">Link to the project</a></li>
                <li>GSoft's sponsor challenge winner in CONUHACKS VII Hackathon - <a href="https://github.com/BFurkan/HRBot" className="text-blue-600">Link to project</a></li>
                <li>Created Android game "Guess It!" using Java and Firebase</li>
              </ul>
            </div>

            <div className="resume-item mb-8">
              <h4 className="text-xl font-semibold text-gray-800">Bachelor of Engineering - Computer Engineering</h4>
              <h5 className="text-gray-600">2016 - 2019</h5>
              <p className="text-gray-600"><em>Gazi University, Ankara, Turkey</em></p>
              <ul className="list-disc ml-5 mt-2 text-gray-600">
                <li>Co-President of BMT (Computer Engineering Association)</li>
                <li>IOTURKS Innovator's winner - Developed Arduino Education Interface</li>
              </ul>
            </div>
          </div>

          {/* Right Column */}

        </div>
      </div>
    </section>
  );
}

export default Resume;
